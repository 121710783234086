import React from 'react'
import Layout from "../layouts/index";
import '../style/index.scss';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import ContactUsForm from '../components/contactUsForm';

const ContactUs = ({ data }) => (
    <Layout children={null} data={data}>
        <div className="contactus">
            <div className="contactus-info">
                <h1>{i18next.t("CONTACTUS_TITLE")}</h1>
                <p className="subtitle">{i18next.t("CONTACTUS_SUBTITLE")}</p>
                <div className="block_infos">
                    <span className="block_infos__phone"><FontAwesomeIcon icon="phone-alt"/><a href={JSON.parse(data.contactinfo.data).phoneLink}>{JSON.parse(data.contactinfo.data).phone}</a></span>
                    <span className="block_infos__email"><FontAwesomeIcon icon="envelope"/><a href={JSON.parse(data.contactinfo.data).emailLink} target="_blank" rel="noreferrer">{JSON.parse(data.contactinfo.data).email}</a></span>
                </div>
            </div>
            <div className="contactus-form">
                <ContactUsForm />
            </div>
        </div>
    </Layout>
)

export const query = graphql`
query ($locale: String!) {
    contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
        data
    }
    categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
        edges {
            node {
                id
                name
                url
                sequence
                locale
                subcategory {
                  id
                  name
                  url
                  locale
                  sequence
                  subcategory {
                    id
                    name
                    url
                    locale
                    sequence
                  }
                }
            }
        }
    }
    contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                locale
                url
                cpContentGridTitle
            }
        }
    }
    allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                footerLinksList
                footerCopyright
                footerPayments
            }
        }
    }
    ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
        nodes {
            submenu {
                header
                directLink
                menu
            }
        }
    }
}
`

export default ContactUs;