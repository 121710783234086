import React from 'react';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import Recaptcha from 'react-invisible-recaptcha';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactUsForm extends React.Component {
    
    constructor(props) {
        super(props);
        //this.ContactForm = React.createRef()
        this.state = {
            "g-recaptcha-response" : "",
            "name": "",
            "email": "",
            "message": "",
            "policy": ""
        };
    }

    componentDidMount() {

        const region = new URLSearchParams(document.location.search.substring(1)).get("region");
        let store = document.getElementById('countrySelect').value;
        if (Cookies.get('store') === undefined && region !== null) {
            switch (region) {
                case "europe":
                    store="eu"
                    break;
                case "us":
                    store="us";
                    break;
                case "uk":
                    store="uk";
                    break;
                case "rest":
                    store="int";
                    break;
                default:
            }
        } else if (Cookies.get('store') !== undefined) {
            store = Cookies.get('store');
        }
        this.setState({
            "store" : store,
            "lang" : i18next.language
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    handleRecaptcha = value => {
        this.setState({ "g-recaptcha-response": value });
    };
    
    /**
     * @function handleFormSubmit
     * @desc this function avoid the netlify response and show OK message on contactus form submission
     */
    handleFormSubmit = (e) => {
        e.preventDefault();
        
        //Reset messages
        document.forms["contactus"].classList.remove("error");
        document.getElementById("contactus-success").classList.add("hidden");
        document.getElementById("contactus-error").classList.add("hidden");
        document.getElementById("policychecklabel").classList.remove("error");

        //Check required fields
        if(this.state.name === undefined) {
            this.setState({ "name": "" });
        }
        if(this.state.email === undefined) {
            this.setState({ "email": "" });
        }
        if(this.state.message === undefined) {
            this.setState({ "message": "" });
        }
        if(this.state.policy === undefined) {
            this.setState({ "policy": "" });
        }
        if(!document.getElementById("policycheck").checked) {
            document.getElementById("policychecklabel").classList.add("error");
        }

        //Check recaptcha
        if (this.state.email !== '' && this.state.name !== '' && this.state.message !== '' && document.getElementById("policycheck").checked) {
            this.recaptcha.execute();
        }
    }
    
    /**
     * @function onRecaptchaResolved
     * @desc this function is invoked when back from google recaptcha
     */
    onRecaptchaResolved = () => {
        this.setState({ "g-recaptcha-response": this.recaptcha.getResponse() });
        const form = document.forms["contactus"];
        const path = window && window.location ? window.location.pathname : "/";
        fetch(path, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
        .then(() => {
            document.getElementById("contactus-success").classList.remove("hidden");
        })
        .catch((error) => {
            console.warn(error);
            document.getElementById("contactus-error").classList.remove("hidden");
        });
    }

    render() {
        return(
            <>
                <div className="formcontactus">
                    <form
                        name="contactus"
                        method="post"
                        action="/"
                        data-netlify="true"
                        data-netlify-honeypot="field-bot-detection"
                        data-netlify-recaptcha="true"
                        onSubmit={this.handleFormSubmit}>
                        <input type="hidden" name="form-name" value="contactus" />
                        <input name="field-bot-detection" onChange={this.handleChange} className="hidden" />
                        <TextField
                            name="name"
                            error={this.state.name === ''}
                            helperText={this.state.name === '' ? i18next.t("CONTACTUS_FORM_REQUIRED_LABEL") : ''}
                            className="formcontactus-input"
                            label={i18next.t("CONTACTUS_FORM_NAME")}
                            aria-label={i18next.t("CONTACTUS_FORM_NAME")}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                        <TextField
                            name="phone"
                            type="tel"
                            className="formcontactus-input"
                            label={i18next.t("CONTACTUS_FORM_PHONE")}
                            aria-label={i18next.t("CONTACTUS_FORM_PHONE")}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                        <TextField
                            name="email"
                            error={this.state.email === ''}
                            helperText={this.state.email === '' ? i18next.t("CONTACTUS_FORM_REQUIRED_LABEL") : ''}
                            type="email"
                            className="formcontactus-input"
                            label={i18next.t("CONTACTUS_FORM_EMAIL")}
                            aria-label={i18next.t("CONTACTUS_FORM_EMAIL")}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                        <TextField
                            name="message"
                            error={this.state.message === ''}
                            helperText={this.state.message === '' ? i18next.t("CONTACTUS_FORM_REQUIRED_LABEL") : ''}
                            multiline
                            rows="5"
                            className="formcontactus-textarea"
                            label={i18next.t("CONTACTUS_FORM_MSG")}
                            aria-label={i18next.t("CONTACTUS_FORM_MSG")}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                        <input name="store" onChange={this.handleChange} className="hidden" />
                        <input name="lang" onChange={this.handleChange} className="hidden" />
						<div className="formcontactus-checkbox">
							<input type="checkbox" id="policycheck" />
							<label htmlFor="policycheck" id="policychecklabel">
                                {i18next.t("CONTACTUS_FORM_POLICY")}
								<a href={i18next.t("CONTACTUS_FORM_PRIVACY_LINK")} target="_blank" rel="noreferrer">
                                    {i18next.t("CONTACTUS_FORM_PRIVACY")}
								</a>
							</label>
						</div>
                        <div className="formcontactus-actions">
                            <Recaptcha
                                ref={ ref => this.recaptcha = ref }
                                sitekey={RECAPTCHA_KEY}
                                onResolved={this.onRecaptchaResolved}
                                locale={this.state.lang}
                                badge="inline"
                            />
                            <button type="submit" className="formcontactus-actions-button">
                                {i18next.t("CONTACTUS_FORM_BUTTON")}<FontAwesomeIcon icon={faAngleRight}/>
                            </button>
                        </div>
                    </form>
                    <span id="contactus-success" className="formcontactus-success hidden">{i18next.t("CONTACTUS_FORM_SUCCESS")}</span>
                    <span id="contactus-error" className="formcontactus-error hidden">{i18next.t("CONTACTUS_FORM_ERROR")}</span>
                </div>
            </>
        );
    }
}

export default ContactUsForm;